import { FormikConfig } from 'formik';
import { InferType, object, string } from 'yup';

export const SignInSchema = object({
  email: string()
    .required('A valid email address is required.')
    .email('A valid email address is required.'),
  formName: string().defined().required().oneOf(['signIn']).default('signIn'),
  password: string()
    .required('Password is required.')
    .matches(/[0-9]/, 'Password must contain a number.')
    .matches(/[a-z]/, 'Password must contain an lowercase letter.')
    .matches(/[A-Z]/, 'Password must contain an uppercase letter.')
    .max(20, 'Password must be less than 20 characters.')
    .min(8, 'Password must be at least 8 characters.'),
});

export type SignInForm = InferType<typeof SignInSchema>;

export const signInForm: FormikConfig<SignInForm> = {
  initialValues: {
    email: '',
    formName: 'signIn',
    password: '',
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onSubmit: () => {}, // We just need a stub, we use the standard form submit
  validateOnBlur: false,
  validateOnChange: true,
  validateOnMount: true,
  validationSchema: SignInSchema,
};
